
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from "@tinymce/tinymce-vue";
import TranslationWrapper from '@/layouts/components/TranslationWrapper.vue'
import TranslationUtil from '@/utils/TranslationUtil'

export default {

  components: {
    Multiselect,
    TranslationWrapper,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      translations: {},
      notificationTemplatesSnippet: snippets ?? {},
      notificationTemplatesList: [],
      paginatedNotificationTemplatesList: [],
      paginatedSearchedNotificationTemplatesList: [],
      notificationTemplatesPaginatedList: [],
      rolesList: [],
      notificationTemplatesListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      addOrEditSpace: '',
      newType: '',
      dialog: false,
      spaceDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogDeletePhoto: false,
      dialogCarousel: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      spaceSubmitted: false,
      mobileNavBar: false,
      enterNewType: false,
      notificationTemplateId: this.$route.params.id,
      editedIndex: -1,
      toDeleteSpace: {},
      editedItem: {
        id: '',
        key: '',
        title: '',
        body: '',
        language: '',
      },
      defaultItem: {
        id: '',
        key: '',
        title: '',
        body: '',
        language: '',
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: '',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldNotificationTemplate: {},
      addressesDataList: [],
      showSpaces: [],
      notificationTemplateSpaces: [],
      notificationTemplateServices: [],
      facilities: [],
      types: [],
      selectedTypeId: '',
      selectedFacilities: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },
    dialogDeletePhoto( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );

    DataEmitterService.$emit( 'route-change' );

    StorageService.deleteNotificationTemplateToStorage();
    next()
  },

  async mounted() {
    await this.getNotificationTemplateApi();

    this.loading = false;
  },

  methods: {

    async getNotificationTemplateApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-notification/${ this.notificationTemplateId }` );
      StorageService.setNotificationTemplateToStorage( this.editedItem );

      DataEmitterService.$emit( 'route-change' );

      this.oldNotificationTemplate = this.getObjClone( this.editedItem );
    },

    async getChangedNotificationTemplateData( oldNotificationTemplate ) {
      const dataFormUpdate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldNotificationTemplate[key] === this.editedItem[key] ) {
          continue
        }

        dataFormUpdate[key] = value
      }

      return dataFormUpdate
    },


    async updateNotificationTemplateRequest( oldNotificationTemplate ) {
      const dataFormUpdate = await this.getChangedNotificationTemplateData( oldNotificationTemplate );
      const updatedNotificationTemplate = await ApiRequestService.updateRequest( `api/admin-notification/${ this.editedItem.id }`, dataFormUpdate );
      if ( updatedNotificationTemplate.errorMessage ) {
        return
      }

      if ( updatedNotificationTemplate ) {
        this.globalError = '';
        this.editedItem = updatedNotificationTemplate;
        this.oldNotificationTemplate = this.getObjClone( this.editedItem );
      }
    },

    dataIsValid() {
      const nameIsValid = this.notificationTemplateIsValid( this.editedItem?.key?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },

    notificationTemplateIsValid( v ) {
      return ( v && v.length > 0 ) || this.notificationTemplatesSnippet.required
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    async updateNotificationTemplate() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        this.globalError = isValid;
        return;
      }

      await this.updateNotificationTemplateRequest( this.oldNotificationTemplate );
    },

    async save() {
      this.submitted = true;
      await this.updateNotificationTemplate();
    },
  }
}

